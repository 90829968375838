<template>
  <div class="not-found-container">
    <img src="@/assets/404.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.not-found-container {
  background: #90cbc0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>